import { Component, createResource, createSignal, For, onMount, Show } from "solid-js";
import { A } from "@solidjs/router";
import { fetchAPI, getAPIUrl, getDiscordUser, getUrlWithActualParams } from "../utils";
import DashboardMiddleware from "../middleware/DashboardMiddleware";
import { AddError, ShowErrorList } from "../index";

const fetchUser = async () => {
  const response = await fetchAPI("/users?discordID=" + getDiscordUser().id, "GET");
  return await response.json();
};

const Account: Component = () => {
  const [localUser] = createSignal<any>(getDiscordUser());
  const [user, { refetch: refetchUser }] = createResource("steamUser", fetchUser);
  const [showRequestLoading, setShowRequestLoading] = createSignal(false);

  const getVerificationToken = async () => {
    const response = await fetchAPI("/users/:discordID/verifications/token", "GET");
    if (!response.ok) return;
    const json = await response.json();
    window.location.href = getUrlWithActualParams(`${getAPIUrl(false)}/steam?verificationCode=${json.token}`);
  };

  const [gmodStorePurchase, { refetch: refetchGmodStorePurchase }] = createResource("gmodStorePurchase", async () => {
    return await fetchAPI("/users/:discordID/gmod-store", "GET").then((res) => res.json() || {});
  });

  const [userDataRequest, { mutate: mutateUserDataRequest }] = createResource("userDataRequest", async () => {
    return await fetchAPI("/users/:discordID/data-requests", "GET").then((res) => res.json() || {});
  });

  function userDataRequestCreate() {
    // in 0.5s setShowRequestLoading to true to show the loading spinner but don't block the request
    let edited = false;
    setTimeout(() => {
      if (edited) return;
      setShowRequestLoading(true);
    }, 500);
    fetchAPI("/users/:discordID/data-requests", "POST").then(async (res) => {
      const json = await res.json();
      edited = true;
      setShowRequestLoading(false);
      if (res.ok) {
        mutateUserDataRequest((prev) => [...prev, json]);
      } else {
        AddError(json.error || "Failed to create the data request");
      }
    });
  }

  onMount(async () => {
    // if url contain startVerification then get the token
    if (window.location.href.includes("startVerification")) {
      await getVerificationToken();
    }
  });

  // const deleteSteamAccount = async () => {
  //   const response = await fetchAPI("/users/steam", "DELETE");
  //   if (response.ok) {
  //     refetchUser();
  //   }
  // };

  return (
    <>
      <DashboardMiddleware />
      <div class="flex flex-col p-4 gap-4 max-w-screen-xl mx-auto w-full">
        <h2 class="text-2xl py-4 font-bold">Account</h2>

        <ShowErrorList />

        <div class="border border-primary rounded-lg">
          <div class="flex flex-col gap-4 p-4">
            <h2 class="text-lg font-bold">Discord</h2>
            {/*<p class="text-gray-500">Name: <span class="font-bold">{localUser().username}</span></p>*/}
            <p class="text-gray-500">
              ID: <span class="font-bold">{localUser().id}</span>
            </p>
          </div>
        </div>

        <div class="border border-primary rounded-lg">
          <div class="flex flex-col gap-4 p-4">
            <h2 class="text-lg font-bold">Steam</h2>
            {/*<Switch fallback={*/}
            <Show
              when={user() && user().steamID64}
              fallback={
                <Show when={user()} fallback={<span class="loading loading-spinner loading-lg"></span>}>
                  <div class="flex flex-raw gap-4">
                    <button class="btn btn-primary" onClick={getVerificationToken}>
                      Link Steam Account
                    </button>
                  </div>
                </Show>
              }
            >
              <p class="text-gray-500">
                ID: <span class="font-bold">{user().steamID64}</span>
              </p>
              {/*<div class="flex flex-raw gap-4">*/}
              {/*  /!*<button class="btn btn-warning" onClick={deleteSteamAccount}>Unlink Steam Account</button>*!/*/}
              {/*  <A*/}
              {/*    href={`https://steamcommunity.com/profiles/${user().steamID64}`}*/}
              {/*    target="_blank"*/}
              {/*    class="btn btn-primary">Open Steam Profile*/}
              {/*  </A>*/}
              {/*</div>*/}
            </Show>
          </div>
        </div>

        <div class="border border-primary rounded-lg">
          <div class="flex flex-col gap-4 p-4">
            <h2 class="text-lg font-bold">Gmod Store Purchase</h2>
            <p class="text-gray-500">Manage your premium bought on Gmod Store.</p>
            <Show when={!gmodStorePurchase.loading} fallback={<span class="loading loading-spinner loading-lg"></span>}>
              <div class="flex flex-raw gap-4">
                <Show
                  when={!gmodStorePurchase().revoke}
                  fallback={
                    <A href="/gmodstore" class="btn btn-primary">
                      Buy Premium
                    </A>
                  }
                >
                  <Show
                    when={gmodStorePurchase().guild}
                    fallback={
                      <A href="/dashboard/guilds" class="btn btn-primary">
                        Activate on Guild
                      </A>
                    }
                  >
                    <p class="text-gray-500">
                      Activate on Guild: <span class="font-bold">{gmodStorePurchase().guild}</span>
                    </p>
                  </Show>
                </Show>
              </div>
            </Show>
            {/*<p class="text-gray-500">We haven't found any purchases on your account, check if your steam account is*/}
            {/*  linked and if you have bought the premium plan on Gmod Store with the same steam account.</p>*/}
          </div>
        </div>

        <div class="border border-primary rounded-lg">
          <div class="flex flex-col gap-4 p-4">
            <h2 class="text-lg font-bold">Manage Data</h2>
            <p class="text-gray-500">In compliance with GDPR, you can request or delete your data.</p>
            <div class="border border-primary rounded-lg">
              <h4 class="text-md font-bold p-4">Data Requests</h4>
              <table class="table">
                <thead>
                  <tr class="text-white text-l">
                    <th class="w-1/5">Request Date</th>
                    <th class="w-1/5">Expiration Date</th>
                    <th class="w-1/5">Status</th>
                    <th class="w-1/6 text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <For each={userDataRequest()}>
                    {(request) => (
                      <tr>
                        <td class="w-1/5">{new Date(request.createdAt).toLocaleString()}</td>
                        <td class="w-1/5">{new Date(request.expirationDate).toLocaleString()}</td>
                        <td class="w-1/5">{new Date(request.expirationDate) > new Date() ? "Active" : "Expired"}</td>
                        <td class="w-1/6 text-center">
                          <Show
                            when={new Date(request.expirationDate) > new Date()}
                            fallback={
                              <div class="tooltip tooltip-error" data-tip="Request has expired">
                                <i class="fa-solid fa-download text-error"></i>
                              </div>
                            }
                          >
                            <div class="tooltip tooltip-primary" data-tip="Download your data">
                              <i
                                class="fa-solid cursor-pointer text-green fa-download"
                                onClick={() => window.open(`${request.downloadLink}?code=${request.code}`)}
                              ></i>
                            </div>
                          </Show>
                        </td>
                      </tr>
                    )}
                  </For>
                  <Show when={showRequestLoading()}>
                    <tr>
                      <td colspan="4" class="text-center">
                        <h4>Depends on your data size, this can take a while.</h4>
                        <progress class="progress w-1/2 my-auto" max="60"></progress>
                      </td>
                    </tr>
                  </Show>
                </tbody>
              </table>
            </div>
            <div class="flex flex-raw gap-4">
              {/*  mail to contact@gmod-integration.com*/}
              <button class="btn btn-primary" onClick={userDataRequestCreate}>
                Request my Data
              </button>
              <a href="mailto:contact@gmod-integration.com" class="btn btn-warning">
                Delete my Data
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Account;
